import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import swal from 'sweetalert';



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const Carousel2 = () =>{
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 6,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
      const [DataPart2 , setDataPart2] = useState([])
      const [product , setproduct] = useState([])



      useEffect(() =>{


      fetch("https://pappu-prado-main-back.vercel.app/AllProduct",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res5=>res5.json())
    .then(res6=>{
     //    console.log(res6);
     const pro = []
     const pro1 = []
     res6.map((res7,i)=>{
         //    console.log(res7.Product_Popular );
        //  if ( res7.Arrival){
           
        //      pro.push(res7)
        //  }
        //  if ( res7.Arrival2){
             //    console.log(res7);
             //         // return setproduct1(...product1 , res3)
             pro1.push(res7)
        //  }
     })
     // setproduct(pro.reverse())
    //  setproduct8(pro)
    //  setproduct9(pro1)
     setproduct(pro1)
    //  setproduct1(pro1.slice(0,8))
     // setproduct1(pro1.slice(0,7))
     // setproduct1(pro1)
    //  setdis(true)
    })


},[])



const addtocartproduct = (productDetail) =>{
    swal("Update!", "Your Item Add in Cart!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
  const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
    return (

    
<>

<main className="main">
<hr  style={{margin:"0px"}}/>


                  <div class="container-fluid categories pb-5">
              <div class="container pb-5">
                  {/* <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}> */}
                      {/* <h1 class="display-5 text-capitalize mb-3">Vehicle <span class="text-primary">Fleets</span></h1> */}
                      {/* <p class="mb-0">Affordable and fuel-efficient cars, perfect for city commuting and cost-effective travel. */}


                      {/* </p> */}
                  {/* </div> */}
                  {/* <span style={{color : "black",fontSize:"18px"}}>{Subproduct.length} <b>{CategoriesName.categoriesName}</b>  founds</span> */}

                  <div class="row" data-wow-delay="0.1s">
                    

{product.map((res,i)=>{
                        return(
                      <div class="col-md-6 col-lg-6 col-xl-4 categories-item mt-1 mb-4">
                          <div class="categories-item-inner">
                              <div class="categories-img rounded-top">
                              <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>

                                  <img src={res.Product_Image_Upload} class="img-fluid w-100 rounded-top" alt="" style={{objectFit: "scale-down",background:"white"}}/>
                             </Link>
                              </div>
                              <div class="categories-content rounded-bottom p-4">
                                  <h4>{res.Product_Name}</h4>
                                  <div class="categories-review mb-4">
                                      <div class="me-3">4.{Math.floor(Math.random() * 5) + 5} Review</div>
                                      <div class="d-flex justify-content-center text-secondary">
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star text-body"></i>
                                      </div>
                                  </div>
                                  {/* <div class="mb-4">
                                      <h4 class="bg-white text-primary rounded-pill py-2 px-4 mb-0">$99:00/Day</h4>
                                  </div> */}
                                  <div class="row gy-2 gx-0 text-center mb-4">
                                      <div class="col-4 border-end border-white">
                                          <i class="fa fa-users text-dark"></i> <span class="text-body ms-1">{res && res.person && res.person} Seat</span>
                                      </div>
                                    
                                      <div class="col-4">
                                          <i class="fa fa-gas-pump text-dark"></i> <span class="text-body ms-1">{res && res.petrol && res.petrol}</span>
                                      </div>
                              
                                      <div class="col-4">
                                          <i class="fa fa-road text-dark"></i> <span class="text-body ms-1">{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</span>
                                      </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-3">
                                        {/* <a class="btn btn-primary rounded-pill d-flex justify-content-center py-3">Call Now</a> */}
                                        <a href="tel:+923306007333" className="shadow-none" ><img src={"https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/accept-call-icon.png"} alt="" style={{width:"40px",height:"40px",marginTop: "-5px"}} /></a>
                                    </div>
                                    <div class="col-6">
                                        <a onClick={()=>addtocartproduct([res])} className="shadow-none" style={{borderRadius:"10px",minWidth:"300px",maxWidth:"350px",color:"white",background :"#5d2222",padding :"9px 13px",color  :"white",fontSize:"17px",fontWeight:"bold"}} >Book Now</a>
                                    </div>
                                    <div class="col-3">
                                        <a href={"https://wa.me/923306007333?text=Hi *One Click Rent a Car karachi*! I need more info about this"+res.productName} className="shadow-none" ><img src={"https://static.vecteezy.com/system/resources/previews/016/716/480/non_2x/whatsapp-icon-free-png.png"} alt="" style={{width:"40px",height:"40px",marginTop: "-5px"}} /></a>
                                        {/* <a  class="btn btn-primary rounded-pill d-flex justify-content-center py-3">Book Now</a> */}
                                    </div>
                                </div>

                              </div>
                          </div>
                      </div>
                        )
                    })
                }
                    
                      
               
              
              
                  
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}


</>

    )
}

export default Carousel2