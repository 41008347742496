import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch,useHistory } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';
import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Checkout2 from './Pages/checkout2';
import Categories from './Pages/Categories';
import Categories1 from './Pages/Categories1';
// import Thankyou from './Pages/Thankyou';
import Header from './Components/Header';
import Footer from './Components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';
import Corporate from './Pages/Corporate';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';
import whatapps1 from './assets/images/png.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Service from './Pages/service';
import Feature from './Pages/feature';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
// import Video from './Pages/video';
import CorporateEquiries from './Pages/CorporateEquiries';
// import submain from './Pages/submain';

// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';






import rentcar from './Pages/rent';
import rentcar1 from './Pages/rent (1)';
import rentcar2 from './Pages/rent (2)';
import rentcar3 from './Pages/rent (3)';
import rentcar4 from './Pages/rent (4)';
import rentcar5 from './Pages/rent (5)';
import rentcar6 from './Pages/rent (6)';
import rentcar7 from './Pages/rent (7)';
import rentcar8 from './Pages/rent (8)';
import rentcar9 from './Pages/rent (9)';
import rentcar10 from './Pages/rent (10)';
import rentcar11 from './Pages/rent (11)';
import rentcar12 from './Pages/rent (12)';
import rentcar13 from './Pages/rent (13)';
import rentcar14 from './Pages/rent (14)';
import rentcar15 from './Pages/rent (15)';
import rentcar16 from './Pages/rent (16)';
import rentcar17 from './Pages/rent (17)';
import rentcar18 from './Pages/rent (18)';
import rentcar19 from './Pages/rent (19)';
import rentcar20 from './Pages/rent (20)';
import rentcar21 from './Pages/rent (21)';
import rentcar22  from './Pages/rent (22)';
import rentcar23 from './Pages/rent (23)';
import rentcar24 from './Pages/rent (24)';
import rentcar25 from './Pages/rent (25)';
import rentcar26 from './Pages/rent (26)';
import rentcar27 from './Pages/rent (27)';
import rentcar28 from './Pages/rent (28)';
import rentcar29 from './Pages/rent (29)';
import rentcar30 from './Pages/rent (30)';
import rentcar31 from './Pages/rent (31)';
import rentcar32 from './Pages/rent (32)';
import rentcar33 from './Pages/rent (33)';
import rentcar34 from './Pages/rent (34)';
import rentcar35 from './Pages/rent (35)';
import rentcar36 from './Pages/rent (36)';
import rentcar37 from './Pages/rent (37)';
import rentcar38 from './Pages/rent (38)';
import rentcar39 from './Pages/rent (39)';
import rentcar40 from './Pages/rent (40)';
import rentcar41 from './Pages/rent (41)';
import rentcar42 from './Pages/rent (42)';
import rentcar43 from './Pages/rent (43)';
import rentcar44 from './Pages/rent (44)';
import rentcar45 from './Pages/rent (45)';
import rentcar46 from './Pages/rent (46)';
import rentcar47 from './Pages/rent (47)';
import rentcar48 from './Pages/rent (48)';
import rentcar49 from './Pages/rent (49)';
import rentcar50 from './Pages/rent (50)';




import rentcars1 from './Pages/rentcars (1)';
import rentcars2 from './Pages/rentcars (2)';
import rentcars3 from './Pages/rentcars (3)';
import rentcars4 from './Pages/rentcars (4)';
import rentcars5 from './Pages/rentcars (5)';
import rentcars6 from './Pages/rentcars (6)';
import rentcars7 from './Pages/rentcars (7)';
import rentcars8 from './Pages/rentcars (8)';
import rentcars9 from './Pages/rentcars (9)';
import rentcars10 from './Pages/rentcars (10)';
import rentcars11 from './Pages/rentcars (11)';
import rentcars12 from './Pages/rentcars (12)';
import rentcars13 from './Pages/rentcars (13)';
import rentcars14 from './Pages/rentcars (14)';
import rentcars15 from './Pages/rentcars (15)';
import rentcars16 from './Pages/rentcars (16)';
import rentcars17 from './Pages/rentcars (17)';
import rentcars18 from './Pages/rentcars (18)';
import rentcars19 from './Pages/rentcars (19)';
import rentcars20 from './Pages/rentcars (20)';
import rentcars21 from './Pages/rentcars (21)';
import rentcars22 from './Pages/rentcars (22)';
import rentcars23 from './Pages/rentcars (23)';
import rentcars24 from './Pages/rentcars (24)';
import rentcars25 from './Pages/rentcars (25)';
import rentcars26 from './Pages/rentcars (26)';
import rentcars27 from './Pages/rentcars (27)';
import rentcars28 from './Pages/rentcars (28)';
import rentcars29 from './Pages/rentcars (29)';
import rentcars30 from './Pages/rentcars (30)';
import rentcars31 from './Pages/rentcars (31)';
import rentcars32 from './Pages/rentcars (32)';
import rentcars33 from './Pages/rentcars (33)';
import rentcars34 from './Pages/rentcars (34)';
import rentcars35 from './Pages/rentcars (35)';
import rentcars36 from './Pages/rentcars (36)';
import rentcars37 from './Pages/rentcars (37)';
import rentcars38 from './Pages/rentcars (38)';
import rentcars39 from './Pages/rentcars (39)';
import rentcars40 from './Pages/rentcars (40)';
import rentcars41 from './Pages/rentcars (41)';
import rentcars42 from './Pages/rentcars (42)';
import rentcars43 from './Pages/rentcars (43)';
import rentcars44 from './Pages/rentcars (44)';
import rentcars45 from './Pages/rentcars (45)';
import rentcars46 from './Pages/rentcars (46)';
import rentcars47 from './Pages/rentcars (47)';
import rentcars48 from './Pages/rentcars (48)';
import rentcars49 from './Pages/rentcars (49)';
import rentcars50 from './Pages/rentcars (50)';
import rentcars51 from './Pages/rentcars (51)';
import rentcars52 from './Pages/rentcars (52)';
import rentcars53 from './Pages/rentcars (53)';
import rentcars54 from './Pages/rentcars (54)';
import rentcars55 from './Pages/rentcars (55)';
import rentcars56 from './Pages/rentcars (56)';
import rentcars57 from './Pages/rentcars (57)';
import rentcars58 from './Pages/rentcars (58)';
import rentcars59 from './Pages/rentcars (59)';
import rentcars60 from './Pages/rentcars (60)';

import rentcars61 from './Pages/rentcars (61)'; // Component for the first route
import rentcars62 from './Pages/rentcars (62)'; // Component for the second route
import rentcars63 from './Pages/rentcars (63)'; // Component for the third route
import rentcars64 from './Pages/rentcars (64)'; // Component for the fourth route
import rentcars65 from './Pages/rentcars (65)'; // Component for the fifth route
import rentcars66 from './Pages/rentcars (66)'; // Component for the sixth route
import rentcars67 from './Pages/rentcars (67)'; // Component for the seventh route
import rentcars68 from './Pages/rentcars (68)'; // Component for the eighth route
import rentcars69 from './Pages/rentcars (69)'; // Component for the ninth route
import rentcars70 from './Pages/rentcars (70)'; // Component for the tenth route
import rentcars71 from './Pages/rentcars (71)'; // Component for the eleventh route
import rentcars72 from './Pages/rentcars (72)'; // Component for the twelfth route
import rentcars73 from './Pages/rentcars (73)'; // Component for the thirteenth route
import rentcars74 from './Pages/rentcars (74)'; // Component for the fourteenth route
import rentcars75 from './Pages/rentcars (75)'; // Component for the fifteenth route
import rentcars76 from './Pages/rentcars (76)'; // Component for the sixteenth route
import rentcars77 from './Pages/rentcars (77)'; // Component for the seventeenth route
import rentcars78 from './Pages/rentcars (78)'; // Component for the eighteenth route
import rentcars79 from './Pages/rentcars (79)'; // Component for the nineteenth route
import rentcars80 from './Pages/rentcars (80)'; // Component for the twentieth route








import rentacarkarachi from './Pages/rentacarkarachi';
import rentacarAirport from './Pages/rentacarAirport';

import rentacarIslamabad from './Pages/rentacarIslamabad';
import rentacarJhelum from './Pages/rentacarJhelum';
import rentacarLahore from './Pages/rentacarLahore';
import rentacarMultan from './Pages/rentacarMultan';
import rentacarPeshawar from './Pages/rentacarPeshawar';

import rentacarHyderabad from './Pages/rentacarHyderabad';



import rentacarKarachiPakistan from './Pages/rentacarKarachi-Pakistan';

import rentacarWedding from './Pages/rentacarWedding-Rent-A-Car';


import rentacarPakistan from './Pages/rentacarPakistan';

import rentacarGulistaneJohar from './Pages/rentacarGulistan-e-Johar';

import testimonials from './Pages/testimonials';




import rentacarinkarachi from './Pages/rentacarinkarachi';
import rentacarserviceinkarachi from './Pages/rentacarserviceinkarachi';
import BulletProofVehiclesAtCarRentalsPakistan from './Pages/Bullet Proof Vehicles At Car Rentals Pakistan';
import carrentalinkarachi from './Pages/carrentalinkarachi';
import carrentalkarachi from './Pages/carrentalkarachi';




import rentacarinkarachiwithdriver from './Pages/rentacarinkarachiwithdriver';


import rentacar11 from './Pages/rentClifton';
import rentacar12 from './Pages/rentDolmen City';
import rentacar13 from './Pages/rentEmpress Market';
import rentacar14 from './Pages/rentGulberg';
import rentacar15 from './Pages/rentGulzar-e-Hijri';
import rentacar16 from './Pages/rentLiaquatabad';
import rentacar17 from './Pages/rentLucky One Mall';
import rentacar18 from './Pages/rentM.A. Jinnah Road';
import rentacar19 from './Pages/rentNew Karachi';
import rentacar20 from './Pages/rentNazimabad';
import rentacar21 from './Pages/rentNorth Nazimabad';
import rentacar22 from './Pages/rentPort Grand';
import rentacar23 from './Pages/rentQuaid e Azam Museum';
import rentacar25 from './Pages/rentkarachi';
import rentacar24 from './Pages/rentTurtle Beach';


import Sitemap from './Pages/sitemap';
import Link1 from './Pages/link';



// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
// import "./all.css"
// import "./custom.css"
// import "./bootsnav.css"
// import "./responsive.css"
// import "./style1.css"
// import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

// import "./css1/custom.css";
// import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
// import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
// import "./css1/all.css";
// import "./css1/style.css";

// import "./css1/bootsnav";

// import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
// import "./asset1/css/plugins/jquery.countdown.css";
// import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
// import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";


import "./asset/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset/css/style.css";






function App(props) {
  let history = useHistory();

  return (
    <div className="App" >                    
     <a href="tel:+923306007333" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "15px",width:"50px",height : "50px"}}/></a>
     <a href="https://wa.me/923306007333?text=Hi *One Click Rent a Car karachi*! I need more info about One Click Rent a Car karachi https://oneclickrentacarkarachi.pk" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "72%" , right : "10px",width:"60px",height : "60px"}}/></a>
{/* <div style={{position : "fixed" ,zIndex : "999999",display:"flex", bottom : "0%" , right : "0px",width:"100%",height : "54px",background:"white"}}>
  <div style={{width:"33.33%",textAlign:"center",color: "#259FDD",fontWeight:"bold",marginTop:"1px"}}> <a href="/contact"><i className="icon-envelope" /> <br />Book </a> </div>
  <div style={{width:"33.33%",textAlign:"center",color: "#259FDD",fontWeight:"bold",marginTop:"1px"}}> <a href="tel:+923161265030"><i className="icon-phone" /><br />Call</a> </div>
  <div style={{width:"33.33%",textAlign:"center",color: "#259FDD",fontWeight:"bold",marginTop:"1px"}}> <a href="https://maps.app.goo.gl/AFjYqqZ7ZvTmXvqy7"> <i className="icon-map-marker" /> <br />Direction</a> </div>
</div> */}
      <Router>

        <Header />
        
        <Switch>
          <Route exact path='/' component={Home} />
          {/* <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          */}
          <Route exact path='/service' component={Service} />
          <Route exact path='/feature' component={Feature} />
          <Route exact path='/Corporate-Equiries' component={CorporateEquiries} />
          {/* <Route exact path='/tourism' component={Tourism} /> */}
          {/* <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} /> */}
          <Route exact path='/testimonial' component={testimonials} />

          {/* <Route exact path='/shop-all' component={ab} /> */}
          <Route exact path='/sitemap' component={Sitemap} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/Corporate-Enquiries' component={Corporate} />
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/checkout2' component={Checkout2} />
          <Route exact path='/FAQ' component={FAQ} />
          {/* <Route exact path='/chat' component={chat} /> */}
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/link' component={Link1} />
          {/* <Route exact path='/track' component={tracking} /> */}
          {/* <Route exact path='/search/product-item' component={SearchData} /> */}
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          {/* <Route exact path='/main-categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/main-categories-car-rental/:categoriesName' component={Categories1} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />

          <Route exact path='/rent-a-car-Wedding-Rent-A-Car' component={rentacarWedding} />
          <Route exact path='/rent-a-car-Karachi-Pakistan' component={rentacarKarachiPakistan} />
       

          <Route exact path='/rent-a-car-Pakistan' component={rentacarPakistan} />
          <Route exact path='/rent-a-car-jhelum' component={rentacarJhelum} />
          
          <Route exact path='/rent-a-car-Gulistan-e-Johar' component={rentacarGulistaneJohar} />
          <Route exact path='/rent-a-car-karachi' component={rentacarkarachi} />

          <Route exact path='/rent-a-car-Islamabad' component={rentacarIslamabad} />
          <Route exact path='/rent-a-car-airport-pick-up &-drop-off' component={rentacarAirport} />
          <Route exact path='/rent-a-car-Hyderabad' component={rentacarHyderabad} />
          <Route exact path='/rent-a-car-Lahore' component={rentacarLahore} />
          <Route exact path='/rent-a-car-Multan' component={rentacarMultan} />
          <Route exact path='/rent-a-car-Peshawar' component={rentacarPeshawar} />




          
          <Route exact path='/rent-a-car-karachi' component={rentcar} />
          
          <Route exact path='/rent-a-car-in-karachi' component={rentcar1} />
          
          <Route exact path='/rent-a-car-in-karachi-with-driver' component={rentcar2} />
          
          <Route exact path='/rent-a-car-in-karachi-with-driver-rate' component={rentcar3} />
          
          <Route exact path='/rent-a-car-with-driver' component={rentcar4} />
          
          <Route exact path='/car-rental-service-online' component={rentcar5} />
          
          <Route exact path='/rent-a-car-in-karachi-with-driver-at-upto-500-pkr-off' component={rentcar6} />
          
          <Route exact path='/car-rental-service-in-karachi' component={rentcar7} />
          
          <Route exact path='/car-rental-services-karachi-rent-a-car-with-driver-karachi' component={rentcar8} />
          
          <Route exact path='/car-rental-in-new-karachi-town' component={rentcar9} />
          
          <Route exact path='/rent-car-services-in-karachi' component={rentcar10} />
          
          <Route exact path='/rent-a-car-karachi-car-rental' component={rentcar11} />
          
          <Route exact path='/rent-car-with-driver-all-over-pakistan' component={rentcar12} />
          
          <Route exact path='/city-car-rentals-karachi' component={rentcar13} />
          
          <Route exact path='/book-your-dream-car-for-rent' component={rentcar14} />
          
          <Route exact path='/rent-a-car-rates-in-karachi' component={rentcar15} />
          
          <Route exact path='/rent-a-car-karachi-car-rental-service-with-driver-pakistan' component={rentcar16} />
          
          <Route exact path='/rent-a-car-karachi-best-cheap-cars-call-now' component={rentcar17} />
          
          <Route exact path='/rent-a-car-in-pakistan-car-rental-hire-online-booking' component={rentcar18} />
          
          <Route exact path='/car-rentals-in-karachi-from-25-day' component={rentcar19} />
          
          <Route exact path='/cheap-car-hire-in-karachi-pakistan-from-20-day' component={rentcar20} />
          
          <Route exact path='/rent-a-car-karachi-cheap-car-rentals-in-karachi' component={rentcar21} />
          
          <Route exact path='/rent-a-car-in-karachi-per-day-rent-a-car-fast-booking' component={rentcar22} />
          
          <Route exact path='/monthly-car-rental-in-karachi' component={rentcar23} />
          
          <Route exact path='/rent-a-car-karachi-no-1-car-rental-company' component={rentcar24} />
          
          <Route exact path='/cheap-car-hire-in-karachi-pakistan' component={rentcar25} />
          
          <Route exact path='/rent-a-car-karachi-get-50-off-best-car-rental-service' component={rentcar26} />
          
          <Route exact path='/benefits-of-monthly-car-rental-in-karachi' component={rentcar27} />
          
          <Route exact path='/rent-a-car-in-karachi-with-affordable-rates' component={rentcar28} />
          
          <Route exact path='/rent-a-car-in-lahore-islamabad-karachi' component={rentcar29} />
          
          <Route exact path='/rent-a-car-call-us-to-book-your-next-trip' component={rentcar30} />
          
          <Route exact path='/rent-a-car-offer-best-service-from-karachi-to-all-over-pakistan' component={rentcar31} />
          
          <Route exact path='/rent-a-car-for-a-month-a-week-or-even-a-day' component={rentcar32} />
          
          <Route exact path='/car-rental-service-with-driver-pakistan' component={rentcar33} />
          
          <Route exact path='/providing-quality-car-rental-services' component={rentcar34} />
          
          <Route exact path='/bullet-proof-vehicles-at-car-rentals-pakistan' component={rentcar35} />
          
          <Route exact path='/bulletproof-vehicle-booking-karachi-pakistan' component={rentcar36} />
          
          <Route exact path='/bullet-proof-car-in-pakistan' component={rentcar37} />
          
          <Route exact path='/toyota-land-cruiser-v8-bullet-proof' component={rentcar38} />
          
          <Route exact path='/armored-car-hire-getcar-pakistans-no-1-car-rental' component={rentcar39} />
          
          <Route exact path='/land-cruiser-v8-zx-bullet-proof' component={rentcar40} />
          
          <Route exact path='/land-cruiser-v8-for-rent-in-karachi' component={rentcar41} />
          
          <Route exact path='/bullet-proof-revo-rent-a-car-in-karachi' component={rentcar42} />
          
          <Route exact path='/rent-a-car-tour-and-tourism-bulletproof-vehicles' component={rentcar43} />
          
          <Route exact path='/rent-a-car-in-karachi-offer-best-prices-best-cars-for-rent' component={rentcar44} />
          
          <Route exact path='/armored-vehicles-rent-in-pakistan' component={rentcar45} />
          
          <Route exact path='/24-7-hour-car-rental-services-across-pakistan' component={rentcar46} />
          
          <Route exact path='/bullet-proof-rentals-karachi' component={rentcar47} />
          
          <Route exact path='/bullet-proof-car-from-pakistan' component={rentcar48} />
          
          <Route exact path='/city-car-rentals-karachi-luxury-cars-for-rent' component={rentcar49} />
          
          <Route exact path='/car-rental-solutions-with-security-guard-company-in-pakistan' component={rentcar50} />





          <Route exact path='/premium-armored-bulletproof-glass-for-cars-in-pakistan' component={rentcars1} />
          <Route exact path='/vigo-rent-services-in-karachi' component={rentcars2} />
          <Route exact path='/toyota-vigo-for-rent-in-karachi' component={rentcars3} />
          <Route exact path='/vigo-rent-car-services-in-karachi' component={rentcars4} />
          <Route exact path='/toyota-hilux-vigo-ready-for-booking' component={rentcars5} />
          <Route exact path='/vigo-rent-a-car-in-karachi-rates' component={rentcars6} />
          <Route exact path='/vigo-rent-a-car-in-karachi-monthly-rates' component={rentcars7} />
          <Route exact path='/vigo-rent-car-rental-in-sindh' component={rentcars8} />
          <Route exact path='/revo-car-rental-in-karachi' component={rentcars9} />
          <Route exact path='/toyota-revo-on-rent-in-karachi' component={rentcars10} />
          <Route exact path='/revo-car-rental-in-pakistan' component={rentcars11} />
          <Route exact path='/rent-a-toyota-revo-in-islamabad' component={rentcars12} />
          <Route exact path='/rent-a-car-in-karachi-pakistan' component={rentcars13} />
          <Route exact path='/rent-a-toyota-prado-search-a-car' component={rentcars14} />
          <Route exact path='/prado-for-rent-services-in-karachi' component={rentcars15} />
          <Route exact path='/land-cruiser-prado-on-rent-in-karachi' component={rentcars16} />
          <Route exact path='/best-car-rental-rates' component={rentcars17} />
          <Route exact path='/toyota-prado-on-rent-deinfa-motors' component={rentcars18} />
          <Route exact path='/rent-a-prado-in-lahore' component={rentcars19} />
          <Route exact path='/suv-prado-and-4x4-for-rent-in-pakistan' component={rentcars20} />
          <Route exact path='/rent-a-car-with-driver-in-karachi' component={rentcars21} />
          <Route exact path='/rent-a-car-pakistan-services' component={rentcars22} />
          <Route exact path='/rent-a-prado-in-islamabad' component={rentcars23} />
          <Route exact path='/rent-a-car-islamabad-lowest-rates' component={rentcars24} />
          <Route exact path='/rent-a-car-lahore-sher-brothers' component={rentcars25} />
          <Route exact path='/rent-a-car-in-islamabad-rozefs-tourism' component={rentcars26} />
          <Route exact path='/rent-a-car-in-abbottabad-rates' component={rentcars27} />
          <Route exact path='/rent-a-car-islamabad-rawalpindi-rates' component={rentcars28} />
          <Route exact path='/luxury-car-rental-services-in-karachi' component={rentcars29} />
          <Route exact path='/v8-car-rental-in-karachi' component={rentcars30} />
          <Route exact path='/land-cruiser-v8-car-rental-in-pakistan' component={rentcars31} />
          <Route exact path='/rent-a-toyota-land-cruiser-search-a-car' component={rentcars32} />          
          <Route exact path='/karachi-pick-and-drop-picnic-rent-a-car' component={rentcars33} />
          <Route exact path='/rent-a-land-cruiser-v8-in-islamabad-rawalpindi' component={rentcars34} />
          <Route exact path='/audi-car-rental-in-karachi' component={rentcars35} />
          <Route exact path='/audi-on-rent-in-karachi' component={rentcars36} />
          <Route exact path='/audi-car-rental-in-pakistan' component={rentcars37} />
          <Route exact path='/rent-a-car-audi-a6-prado-v8-zx-fortuner' component={rentcars38} />
          <Route exact path='/car-rentals-pakistan-rent-a-car-in-islamabad' component={rentcars39} />
          <Route exact path='/karachi-rent-a-car-home-car' component={rentcars40} />
          <Route exact path='/audi-a3-for-rent-in-islamabad-rawalpindi' component={rentcars41} />
          <Route exact path='/rent-a-audi-a5-search-a-car' component={rentcars42} />
          <Route exact path='/rent-a-cars-services-in-gulistan-e-jauhar-block-15' component={rentcars43} />
          <Route exact path='/car-rental-service-in-gulistan-e-jauhar-block-15' component={rentcars44} />
          <Route exact path='/rent-a-car-service-in-gulistan-e-johar-karachi' component={rentcars45} />
          <Route exact path='/here-is-how-you-can-rent-a-car-in-gulistan-e-johar-karachi' component={rentcars46} />
          <Route exact path='/rent-a-car-service-in-gulistan-e-johar' component={rentcars47} />
          <Route exact path='/auto-rent-a-car-gulistan-e-johar-karachi' component={rentcars48} />
          <Route exact path='/car-rental-service-in-gulshan-e-maymar' component={rentcars49} />
          <Route exact path='/carplus-rent-a-car-gulshan-e-maymar' component={rentcars50} />
          <Route exact path='/karachi-car-rental-in-gulshan-e-maymar' component={rentcars51} />
          <Route exact path='/pick-and-drop-services-at-your-place-gulshan-e-maymar' component={rentcars52} />
          <Route exact path='/rent-a-cars-services-in-gulshan-e-iqbal-block-4' component={rentcars53} />
          <Route exact path='/rent-a-car-service-in-gulshan-e-iqbal-karachi' component={rentcars54} />
          <Route exact path='/rent-car-car-rental-in-gulshan-e-iqbal-block-4' component={rentcars55} />
          <Route exact path='/rent-a-car-service-in-gulshan-e-iqbal' component={rentcars56} />
          <Route exact path='/car-hire-in-gulshan-e-iqbal-karachi' component={rentcars57} />
          <Route exact path='/raza-khan-tours-rent-a-car-gulshan-e-iqbal-block-3' component={rentcars58} />
          <Route exact path='/cheap-car-hire-in-gulshan-e-iqbal' component={rentcars59} />
          <Route exact path='/royal-rent-a-car-in-karachi-3000pkr-per-day' component={rentcars60} />



          <Route exact path='/affordable-car-rental-service-in-gulshan-e-iqbal-karachi' component={rentcars61} />
            <Route exact path='/rent-a-car-gulshan-e-iqbal-karachi-contact-details' component={rentcars62} />
            <Route exact path='/hire-car-rentals-in-karachi' component={rentcars63} />
            <Route exact path='/cheap-car-rentals-gulshan-e-iqbal' component={rentcars64} />
            <Route exact path='/dha-clifton-car-rental-in-karachi' component={rentcars65} />
            <Route exact path='/rent-a-car-service-in-defence-karachi' component={rentcars66} />
            <Route exact path='/rent-a-car-services-in-dha-phase-2' component={rentcars67} />
            <Route exact path='/captain-car-rental-service-in-karachi' component={rentcars68} />
            <Route exact path='/rent-a-car-service-in-dha-karachi' component={rentcars69} />
            <Route exact path='/dha-rent-a-car-service-in-karachi' component={rentcars70} />
            <Route exact path='/affordable-rent-a-car-dha' component={rentcars71} />
            <Route exact path='/car-rental-service-in-do-talwar' component={rentcars72} />
            <Route exact path='/rent-a-car-service-in-defence-zone' component={rentcars73} />
            <Route exact path='/car-rental-service-in-dha' component={rentcars74} />
            <Route exact path='/luxury-car-rentals-in-dha' component={rentcars75} />
            <Route exact path='/rent-a-car-dha-clifton' component={rentcars76} />
            <Route exact path='/karachi-dha-rental-car-services' component={rentcars77} />
            <Route exact path='/car-rental-service-in-clifton' component={rentcars78} />
            <Route exact path='/rent-a-car-service-in-clifton' component={rentcars79} />
            <Route exact path='/karachi-car-rental-clifton' component={rentcars80} />
































        
          <Route exact path='/rent-a-car-airport-pick-up &-drop-off' component={rentacarJhelum} />
      
          <Route exact path='/rent-a-car-in-clifton' component={rentacar11} />
          <Route exact path='/rent-a-car-in-dolmen-city' component={rentacar12} />
          <Route exact path='/rent-a-car-in-empress-market' component={rentacar13} />
          <Route exact path='/rent-a-car-in-gulberg' component={rentacar14} />
          <Route exact path='/rent-a-car-in-gulzar-e-hijri' component={rentacar15} />
          <Route exact path='/rent-a-car-in-liaquatabad' component={rentacar16} />
          <Route exact path='/rent-a-car-in-lucky-one-mall' component={rentacar17} />
          <Route exact path='/rent-a-car-in-m-a-jinnah-road' component={rentacar18} />
          <Route exact path='/rent-a-car-in-new-karachi' component={rentacar19} />
          <Route exact path='/rent-a-car-in-nazimabad' component={rentacar20} />
          <Route exact path='/rent-a-car-in-north-nazimabad' component={rentacar21} />
          <Route exact path='/rent-a-car-in-port-grand' component={rentacar22} />
          <Route exact path='/rent-a-car-in-quaid-e-azam-museum' component={rentacar23} />
          <Route exact path='/rent-a-car-in-turtle-beach' component={rentacar24} />
          <Route exact path='/rentacarkarachi' component={rentacar25} />



          <Route exact path='/Bullet-Proof-Vehicles-At-Car-Rentals-karachi-Pakistan' component={BulletProofVehiclesAtCarRentalsPakistan} />
          <Route exact path='/rent-a-car-in-karachi' component={rentacarinkarachi} />
          <Route exact path='/rent-a-car-in-karachi-with-driver' component={rentacarinkarachiwithdriver} />
          <Route exact path='/rent-a-car-karachi' component={rentacarkarachi} />
          <Route exact path='/rent-a-car-service-in-karachi' component={rentacarserviceinkarachi} />
          <Route exact path='/car-rental-in-karachi' component={carrentalinkarachi} />
          <Route exact path='/car-rental-karachi' component={carrentalkarachi} />
     
          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
