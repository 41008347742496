import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';
import swal from 'sweetalert';


import logo1 from '../assets/images/logo-kar.png';
// import logo1 from '../assets/images/apple-touch-icon.png';
// import Head1 from "../assets/images/header-bg-rpt.jpg"
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
// import MenuIcon from '@material-ui/icons/Menu';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
// // import AddAlertIcon from '@mui/icons-material/AddAlert';
// import SendIcon from '@material-ui/icons/Send';
// import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import { useSelector, useDispatch } from 'react-redux';

import Loader from "react-loader-spinner";



const Header =(props)=> {

  const [total1 , settotal1] = useState(0)
    const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    // const [menu1 , setmenu1] = useState(false)
    // const [adminHeader , setadminHeader] = useState(false)
    // const [displayMenu , setdisplayMenu] = useState(false)
    // const [MainLine , setMainLine] = useState("")
    // const [MainForum , setMainForum] = useState(false)
    const [MainCatogories , setMainCategories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [fvalue , setfvalue] = useState([])
    // const [fvalue1 , setfvalue1] = useState([])
    // const [fg , setfg] = useState(true)
    // const [abc , setabc] = useState(false)
    const [DisplaySearch , setDisplaySearch] = useState(false)
    // const [DisplayMenu , setDisplayMenu] = useState(false)
    // const [bht , setbht] = useState("")
    const [mainUser , setmainUser] = useState({})
    // const [couponShow1 , setcouponShow1] = useState(false)
    // const [name, setname] = useState("") 
    // const [email, setemail] = useState("") 
    // const [message, setmessage] = useState("") 
    // const [photo, setphoto] = useState("") 
    // const [allmessage , setallmessage] = useState([])
    const [cart1 , setcart1] = useState([])
    const [fav1 , setfav1] = useState([])
    // const [photo, setphoto] = useState("") 

    let history = useHistory();


    useEffect(() =>{
      setTimeout(()=>{
        document.getElementById("spinner").style.visibility = "hidden"
      },3000)
    },[])
  

    const changeFruit = (goto1) => {
      // setCurrentFruit(newFruit)
      // history.push(goto);
      window.location = goto1;
    }
    

    const SubmitData45342 = (e) =>{
      e.preventDefault()
      console.log("df")
      setDisplaySearch(true)
      localStorage.removeItem("SearchData")
          fetch("https://pappu-prado-main-back.vercel.app/SearchProduct",{
                                  method: "POST",
                                  headers :  {
                                      "Content-Type" : "application/json" , 
                                  } ,
                                  body : JSON.stringify({
                                      search : search.toLowerCase() ,
      
                                  })
                              })
                              .then(res=>res.json())
                              .then((res1)=>{ 
                                
                                console.log(res1) 
                                  const SearchProductItem = []
                                  
                                  let search1 = search.split(" ")
                                res1.map((res2,i)=>{
                                    // console.log(res2.Product_Name.toLowerCase().split(" "),res2.Product_Title.toLowerCase().split(" "),i)
                                    var Name = res2.Product_Name.toLowerCase().split(" ")
                                     var Title = res2.Product_Title.toLowerCase().split(" ")
                                    // console.log(Name , Title)
                                    for (let j = 0; j < search1.length; j++) {
                                      
                                      Name.map((res3,i)=>{
  
                                          if (res3 === search1[j].toLowerCase().trim()){
                                              SearchProductItem.unshift(res2)
                                          }
                                      })
                                      Title.map((res4,i)=>{
                                        if (res4 === search1[j].toLowerCase().trim()){
                                          SearchProductItem.unshift(res2)
                                        }
                                      })
                                    }
                                })
                                  console.log(SearchProductItem)    

                                  console.log(SearchProductItem,props)
                                  let uniq = [...new Set(SearchProductItem)];
      
                                  localStorage.setItem("SearchData" , JSON.stringify(uniq) )
                                  
                                  setDisplaySearch(false)
                                  setTimeout(()=>{
                                      history.push("/search/product-item");
      
                                      // return <Redirect push to="/shop/search/product-item" /> 
                                      // props.history.push(`/shop/search/product-item`)
                                  },1000)   
                                  // localStorage.setItem("SearchData" , JSON.stringify(res1) )
                                  // props.history.push(`/shop/search/product-item`)
      
                                  
                          })
                          .catch(err=>{
                            console.log(err)
                            swal("There is An Error")                  
                            setDisplaySearch(false)
                              }) 
      }

const ChangeCateUrl = (res) =>{
  console.log("sfsfd")
  history.push("/categories/"+res+"/all-products")
    
}




    
const setDisplayMenu3 =() =>{
  if(document.getElementById("menu-mobile-1").style.visibility === "hidden"){
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "visible"
    document.getElementById("menu-mobile-2").style.visibility = "visible"
  }
  else{
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "hidden"
    document.getElementById("menu-mobile-2").style.visibility = "hidden"
  }
}
        return (
            <>
             { DisplaySearch ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} 
        className="loader-1"
      /> 
      :""
}  
<div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
              <div class="spinner-border text-primary" style={{width: "3rem", height: "3rem"}} role="status">
                  <span class="sr-only">Loading...</span>
              </div>
          </div>
  
          <div class="container-fluid topbar bg-secondary d-none d-xl-block w-100" style={{padding: "10px 0px"}}>
              <div class="container">
                  <div class="row gx-0 align-items-center" style={{height: "45px"}}>
                      <div class="col-lg-6 text-center text-lg-start mb-lg-0">
                          <div class="d-flex flex-wrap">
                              <a href="tel:+923306007333" class="text-muted me-4 text-white"  style={{color :"white"}}><i class="fas fa-phone-alt text-primary me-2"></i>+92 326 2021001</a>
                              <a href="mailto:oneclickrentacarkarachi@gmail.com" class="text-muted me-0 text-white"   style={{color :"white"}}><i class="fas fa-envelope text-primary me-2"></i>oneclickrentacarkarachi@gmail.com</a>
                          </div>
                      </div>
                      <div class="col-lg-6 text-center text-lg-end">
                          <div class="d-flex align-items-center justify-content-end">
                              <a href="https://www.facebook.com/webocreators" class="btn-light btn-sm-square rounded-circle me-3"><i class="fab fa-facebook-f"></i></a>
                              <a href="http://tiktok.com/webocreators" class="btn-light btn-sm-square rounded-circle me-3"><i class="fab fa-tiktok"></i></a>
                              <a href="http://instagram.com/webocreators" class="btn-light btn-sm-square rounded-circle me-3"><i class="fab fa-instagram"></i></a>
                              <a href="https://www.linkedin.com/in/webocreators" class="btn-light btn-sm-square rounded-circle me-0"><i class="fab fa-linkedin-in"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          <div class="container-fluid nav-bar sticky-top px-0 px-lg-4 py-2 py-lg-0">
              <div class="container">
                  <nav class="navbar navbar-expand-lg navbar-light">
                      <Link to="/" class="navbar-brand p-0">
                          <img src={logo1} alt="" style={{   width: "147px",
    minHeight: "121px",
    objectFit: "contain"}} />
                      </Link>
                      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                          <span class="fa fa-bars"></span>
                      </button>
                      <div class="collapse navbar-collapse" id="navbarCollapse">
                          <div class="navbar-nav mx-auto py-0">
                              {/* <Link to="/" class="nav-item nav-link active">Home</Link> */}
                              <Link to="/about" class="nav-item nav-link">About Us</Link>
                              <Link to="/categories/Cars/all-products" class="nav-item nav-link">Vehicles </Link>
                              <Link to="/Corporate-Enquiries" class="nav-item nav-link">Corporate Enquiries </Link>
                              <div class="nav-item dropdown">
                                  <a to="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                  <div class="dropdown-menu m-0">
                                  <Link  class="dropdown-item" to="/rent-a-car-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link>
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Defence">Rent A Car in Defence</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Sindhi-Muslim-Society">Rent A Car in Sindhi Muslim Society</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Malir-Cantt">Rent A Car in Malir Cantt</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Korangi-Industrial-Area">Rent A Car in Korangi Industrial Area</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Bahria-Town-Karachi">Rent A Car in Bahria Town Karachi</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Clifton">Rent A Car in Clifton</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Port-Qasim">Rent A Car in Port Qasim</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Nizamabad">Rent A Car in Nizamabad</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Bufferzone">Rent A Car in Bufferzone</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Tariq-Road">Rent A Car in Tariq Road</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Baldia-Town">Rent A Car in Baldia Town</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-North-Nizamabad">Rent A Car in North-Nizamabad</Link> */}
                                              <Link  class="dropdown-item" to="/rent-a-car-Gulistan-e-Johar">Rent A Car in Gulistan-e-Johar</Link>
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Gulshan-e-Iqbal">Rent A Car in Gulshan-e-Iqbal</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Gulshan-e-Hadeed">Rent A Car in Gulshan-e-Hadeed</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Gulshan-e-Maymar">Rent A Car in Gulshan-e-Maymar</Link> */}
                                               <Link  class="dropdown-item" to="/rent-a-car-karachi">Rent A Car in karachi</Link>
                                               {/* <Link  class="dropdown-item" to="/car-rental-in-karachi-pakistan">Car Rental in karachi Pakistan</Link> */}
                                               <Link  class="dropdown-item" to="/rent-a-car-Lahore">Rent A Car in Lahore</Link>
                          

                                               <Link  class="dropdown-item" to="/rentacarkarachi">Rent a Car in Karachi</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Karachi-Pakistan">Rent A Car Karachi Pakistan</Link>

                                               <Link  class="dropdown-item" to="/rent-a-car-in-karachi-with-driver">Rent A Car karachi with driver</Link>
                                               <Link  class="dropdown-item" to="/rent-a-car-karachi">Rent A Car karachi</Link>
                                            
                                              <Link  class="dropdown-item" to="/rent-a-car-Gulistan-e-Johar">Rent A Car Gulistan-e-Johar</Link>
                                              <Link  class="dropdown-item" to="/Bullet-Proof-Vehicles-At-Car-Rentals-karachi-Pakistan">Bullet Proof Vehicles At Car Rentals karachi Pakistan</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-clifton"> Rent A Car Clifton</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-dolmen-city"> Rent A Car Dolmen City</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-empress-market"> Rent A Car Empress Market</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-gulberg"> Rent A Car Gulberg</Link>
                                            

                                             
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi">Rent A Car Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi">Rent A Car in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi-with-driver">Rent A Car in Karachi with driver</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi-with-driver-rate">Rent A Car in Karachi with driver rate</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-with-driver">Rent A Car with driver</Link>
                                                <Link  class="dropdown-item" to="/car-rental-service-online">Car Rental Service Online</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi-with-driver-upto-500-off">Rent A Car in Karachi with Driver at Upto 500 PKR Off</Link>
                                                <Link  class="dropdown-item" to="/car-rental-service-in-Karachi">Car Rental Service in Karachi</Link>
                                                <Link  class="dropdown-item" to="/car-rental-services-Karachi">Car Rental Services Karachi - Rent A Car with Driver Karachi</Link>
                                                <Link  class="dropdown-item" to="/car-rental-in-New-Karachi-Town">Car rental in New Karachi Town</Link>
                                                <Link  class="dropdown-item" to="/rent-car-services-in-Karachi">Rent Car - Services in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi-car-rental">Rent A Car Karachi Car Rental</Link>
                                                <Link  class="dropdown-item" to="/rent-car-with-driver-all-over-Pakistan">Rent Car with Driver all Over Pakistan</Link>
                                                <Link  class="dropdown-item" to="/city-car-rentals-Karachi">City Car Rentals Karachi</Link>
                                                <Link  class="dropdown-item" to="/book-your-dream-car-for-rent">Book Your Dream Car for Rent</Link>
                                               
                          
          
                                  
              
             
                                              <Link  class="dropdown-item" to="/rent-a-car-Pakistan">Rent A Car in Pakistan</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Islamabad">Rent A Car in Islamabad</Link>
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link> */}
                                             
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link> */}
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link> */}
                                              <Link  class="dropdown-item" to="/rent-a-car-Hyderabad">Rent A Car in Hyderabad</Link>
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link> */}
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link> */}
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link> */}
                                              <Link  class="dropdown-item" to="/rent-a-car-Peshawar">Rent A Car in Peshawar</Link>
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link> */}


<Link  class="dropdown-item" to="/rent-a-car-Pakistan">Rent A Car Pakistan</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Karachi-Pakistan">Rent A Car Peshawar</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Islamabad">Rent A Car Islamabad</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-airport-pick-up &-drop-off">Rent A Car Airport Pickup & Drop Off</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-gulzar-e-hijri"> Rent A Car Gulzar-e-Hijri</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-liaquatabad"> Rent A Car Liaquatabad</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-lucky-one-mall"> Rent A Car Lucky One Mall</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-m-a-jinnah-road"> Rent A Car M.A. Jinnah Road</Link>
                                            
                                              <Link  class="dropdown-item" to="/rent-a-car-rates-in-Karachi">Rent a car rates in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi-car-rental-service-with-driver-Pakistan">Rent a Car Karachi - Car Rental Service with Driver Pakistan</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi-best-cheap-cars">Rent a Car Karachi - Best & Cheap Cars Call Now</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Pakistan-hire-online-booking">Rent a Car in Pakistan - Car Rental, Hire Online Booking</Link>
                                                <Link  class="dropdown-item" to="/car-rentals-in-Karachi-from-25-day">Car Rentals in Karachi from $25/day</Link>
                                                <Link  class="dropdown-item" to="/cheap-car-hire-in-Karachi-Pakistan-from-20-day">Cheap car hire in Karachi, Pakistan from £20/day</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi-cheap-car-rentals">Rent a Car Karachi | Cheap Car Rentals in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi-per-day-fast-booking">Rent a car in Karachi per day - Rent a car | Fast Booking</Link>
                                                <Link  class="dropdown-item" to="/monthly-car-rental-in-Karachi">Monthly - Car Rental in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi-No1-car-rental-company">Rent A Car Karachi | No.1 Car Rental Company</Link>
                                                <Link  class="dropdown-item" to="/cheap-car-hire-in-Karachi-Pakistan">Cheap car hire in Karachi, Pakistan</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-Karachi-get-50-off">Rent a Car Karachi | Get 50% Off | Best Car Rental Service</Link>
                                                <Link  class="dropdown-item" to="/benefits-of-monthly-car-rental-in-Karachi">Benefits of Monthly car rental in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi-with-affordable-rates">Rent a Car in Karachi with Affordable Rates</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Lahore-Islamabad-Karachi">Rent a car in Lahore, Islamabad & Karachi</Link>
                                      
                            
                                         




    
            <Link  class="dropdown-item" to="/rent-a-car-Multan">Rent A Car in Multan</Link>
            <Link  class="dropdown-item" to="/rent-a-car-jhelum">Rent a Car Jhelum</Link>

                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link> */}
                                              {/* <Link  class="dropdown-item" to="/categories/One%20Way%20Drop/all-products">One Way Drop</Link>
                                              <Link  class="dropdown-item" to="/one-way-drop-in-karachi-to-islamabad">One Way Drop karachi to Islamabad</Link>
                                              <Link  class="dropdown-item" to="/one-way-drop-in-karachi-to-faisalabad">One Way Drop karachi to faisalabad</Link>
                                              <Link  class="dropdown-item" to="/one-way-drop-in-karachi-to-multan">One Way Drop karachi to Multan</Link>
                                              <Link  class="dropdown-item" to="/one-way-drop-in-karachi-to-lahore">One Way Drop karachi to lahore</Link> */}
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-airport-pick-up &-drop-off">Rent A Car For Airport Pick & Drop</Link> */}
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Airport-Pick-&-Drop">Rent A Car For Airport Pick & Drop</Link> */}
                                              <Link  class="dropdown-item" to="/rent-a-car-Wedding-Rent-A-Car">Rent A Car in Wedding Transport</Link>
                                              {/* <Link  class="dropdown-item" to="/rent-a-car-in-Landhi-Industrial-Area">Rent A Car in Landhi Industrial Area</Link>
                                              <Link  class="dropdown-item" to="/tourism">Rent A Car For Tourism</Link> */}


    
<Link  class="dropdown-item" to="/rent-a-car-Lahore">Rent A Car Lahore</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Multan">Rent A Car Multan</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Hyderabad">Rent A Car Hyderabad</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-Wedding-Rent-A-Car">Rent A Car Wedding Transport</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-new-karachi"> Rent A Car New Karachi</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-nazimabad"> Rent A Car Nazimabad</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-north-nazimabad"> Rent A Car North Nazimabad</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-port-grand"> Rent A Car Port Grand</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-quaid-e-azam-museum"> Rent A Car Quaid e Azam Museum</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-in-turtle-beach"> Rent A Car Turtle Beach</Link>
                                              <Link  class="dropdown-item" to="/rent-a-car-call-us-to-book-your-next-trip">Rent A Car - Call Us To Book Your Next Trip</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-offer-best-service-from-Karachi-to-all-over-Pakistan">Rent a Car Offer Best Service from Karachi to All Over Pakistan</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-for-month-week-day">Rent a car for a month, a week, or even a day</Link>
                                                <Link  class="dropdown-item" to="/car-rental-service-with-driver-Pakistan">Car Rental Service with Driver Pakistan</Link>
                                                <Link  class="dropdown-item" to="/providing-quality-car-rental-services">Providing Quality Car Rental Services</Link>
                                                <Link  class="dropdown-item" to="/bullet-proof-vehicles-at-car-rentals-Pakistan">Bullet Proof Vehicles At Car Rentals Pakistan</Link>
                                                <Link  class="dropdown-item" to="/bulletproof-vehicle-booking-Karachi-Pakistan">Bulletproof Vehicle Booking Karachi Pakistan</Link>
                                                <Link  class="dropdown-item" to="/bullet-proof-car-in-Pakistan">Bullet Proof Car in Pakistan</Link>
                                                <Link  class="dropdown-item" to="/toyota-land-cruiser-v8-bullet-proof">Toyota Land Cruiser V8 (Bullet Proof)</Link>
                                                <Link  class="dropdown-item" to="/armored-car-hire-getcar-Pakistan-no1-car-rental">Armored Car Hire – #getcar | Pakistan's No. 1 Car Rental</Link>
                                                <Link  class="dropdown-item" to="/land-cruiser-v8-zx-bullet-proof">Land Cruiser V8 ZX Bullet Proof</Link>
                                                <Link  class="dropdown-item" to="/land-cruiser-v8-for-rent-in-Karachi">Land Cruiser V8 for rent in Karachi</Link>
                                                <Link  class="dropdown-item" to="/bullet-proof-revo-rent-a-car-in-Karachi">Bullet Proof Revo - Rent a Car in Karachi</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-tour-and-tourism-bulletproof-vehicles">Rent a car/ tour and tourism/ Bulletproof Vehicles</Link>
                                                <Link  class="dropdown-item" to="/rent-a-car-in-Karachi-best-prices-best-cars-for-rent">Rent A Car in Karachi || Offer Best Prices || Best Cars For Rent</Link>
                                                <Link  class="dropdown-item" to="/armored-vehicles-rent-in-Pakistan">ARMORED VEHICLES Rent in PAKISTAN</Link>
                                                <Link  class="dropdown-item" to="/24-7-hour-car-rental-services-across-Pakistan">24/7 Hour Car Rental Services Across Pakistan</Link>
                                                <Link  class="dropdown-item" to="/bullet-proof-rentals-Karachi">Bullet Proof Rentals Karachi</Link>
                                                <Link  class="dropdown-item" to="/bullet-proof-car-from-Pakistan">Bullet Proof Car from Pakistan</Link>
                                                <Link  class="dropdown-item" to="/city-car-rentals-Karachi-luxury-cars-for-rent">City Car Rentals Karachi - Luxury Cars For Rent</Link>
                                                <Link  class="dropdown-item" to="/car-rental-solutions-with-security-guard-company-in-Pakistan">Car Rental Solutions with Security Guard Company in Pakistan</Link>




<Link className="dropdown-item" to="/premium-armored-bulletproof-glass-for-cars-in-pakistan">Premium Armored Bulletproof Glass for Cars in Pakistan</Link>
<Link className="dropdown-item" to="/vigo-rent-services-in-karachi">Vigo Rent - Services in Karachi</Link>
<Link className="dropdown-item" to="/toyota-vigo-for-rent-in-karachi">Toyota Vigo for Rent in Karachi</Link>
<Link className="dropdown-item" to="/vigo-rent-car-services-in-karachi">Vigo Rent Car - Services in Karachi</Link>
<Link className="dropdown-item" to="/toyota-hilux-vigo-ready-for-booking">Toyota Hilux Vigo is Ready for Booking Now</Link>
<Link className="dropdown-item" to="/vigo-rent-a-car-in-karachi-rates">Vigo Rent a Car in Karachi Rates</Link>
<Link className="dropdown-item" to="/vigo-rent-a-car-in-karachi-monthly-rates">Vigo Rent a Car in Karachi Monthly Rates</Link>
<Link className="dropdown-item" to="/vigo-rent-car-rental-in-sindh">Vigo Rent - Car Rental in Sindh</Link>
<Link className="dropdown-item" to="/revo-car-rental-in-karachi">Revo - Car Rental in Karachi</Link>
<Link className="dropdown-item" to="/toyota-revo-on-rent-in-karachi">Toyota Revo on Rent in Karachi</Link>
<Link className="dropdown-item" to="/revo-car-rental-in-pakistan">Revo - Car Rental in Pakistan</Link>
<Link className="dropdown-item" to="/rent-a-toyota-revo-in-islamabad">Rent a Toyota Revo - Rent A Car In Islamabad</Link>
<Link className="dropdown-item" to="/rent-a-car-in-karachi-pakistan">Rent a Car in Karachi Pakistan</Link>
<Link className="dropdown-item" to="/rent-a-toyota-prado-search-a-car">Rent A Toyota Prado | Search A Car | Car Rental Services</Link>
<Link className="dropdown-item" to="/prado-for-rent-services-in-karachi">Prado For Rent - Services in Karachi</Link>
<Link className="dropdown-item" to="/land-cruiser-prado-on-rent-in-karachi">Land Cruiser Prado on Rent in Karachi</Link>
<Link className="dropdown-item" to="/best-car-rental-rates">Best Car Rental Rates</Link>
<Link className="dropdown-item" to="/toyota-prado-on-rent-deinfa-motors">Toyota Prado on Rent - Deinfa Motors Rental Vehicles</Link>
<Link className="dropdown-item" to="/rent-a-prado-in-lahore">Rent a Prado in Lahore - Prado Rent Per Day</Link>
<Link className="dropdown-item" to="/suv-prado-and-4x4-for-rent-in-pakistan">SUV Prado and 4x4 for Rent At Car Rentals Pakistan</Link>
<Link className="dropdown-item" to="/rent-a-car-with-driver-in-karachi">Rent a Car with Driver in Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-pakistan-services">Rent A Car Pakistan Services | Pak Car Rentals</Link>
<Link className="dropdown-item" to="/rent-a-prado-in-islamabad">Rent a Prado in Islamabad</Link>
<Link className="dropdown-item" to="/rent-a-car-islamabad-lowest-rates">Rent a Car Islamabad | 2020 Lowest Rates Guaranteed</Link>
<Link className="dropdown-item" to="/rent-a-car-lahore-sher-brothers">Rent a Car Lahore - Sher Brothers Car Rental</Link>
<Link className="dropdown-item" to="/rent-a-car-in-islamabad-rozefs-tourism">Rent a Car in Islamabad - Rozefs Tourism</Link>
<Link className="dropdown-item" to="/rent-a-car-in-abbottabad-rates">Rent a Car in Abbottabad Rates</Link>
<Link className="dropdown-item" to="/rent-a-car-islamabad-rawalpindi-rates">Rent A Car Islamabad & Rawalpindi Rates</Link>
<Link className="dropdown-item" to="/luxury-car-rental-services-in-karachi">Luxury Car Rental Services in Karachi</Link>
<Link className="dropdown-item" to="/v8-car-rental-in-karachi">V8 - Car Rental in Karachi</Link>
<Link className="dropdown-item" to="/land-cruiser-v8-car-rental-in-pakistan">Land Cruiser V8 - Car Rental in Pakistan</Link>
<Link className="dropdown-item" to='/rent-a-toyota-land-cruiser-search-a-car'>Rent a Toyota Land Cruiser Search a Car</Link>
<Link className="dropdown-item" to="/karachi-pick-and-drop-picnic-rent-a-car">Karachi Pick and Drop - Picnic - Rent A Car</Link>
<Link className="dropdown-item" to="/rent-a-land-cruiser-v8-in-islamabad-rawalpindi">Rent A Land Cruiser V8 In Islamabad & Rawalpindi</Link>
<Link className="dropdown-item" to="/audi-car-rental-in-karachi">Audi - Car Rental in Karachi</Link>
<Link className="dropdown-item" to="/audi-on-rent-in-karachi">Audi on Rent in Karachi</Link>
<Link className="dropdown-item" to="/audi-car-rental-in-pakistan">Audi - Car Rental in Pakistan</Link>
<Link className="dropdown-item" to="/rent-a-car-audi-a6-prado-v8-zx-fortuner">Rent a Car | AUDI A6 | PRADO V8 | ZX | FORTUNER</Link>
<Link className="dropdown-item" to="/car-rentals-pakistan-rent-a-car-in-islamabad">Car Rentals Pakistan: Rent a Car in Islamabad Pakistan</Link>
<Link className="dropdown-item" to="/karachi-rent-a-car-home-car">Karachi Rent a Car: Home Car</Link>
<Link className="dropdown-item" to="/audi-a3-for-rent-in-islamabad-rawalpindi">Audi A3 For Rent in Islamabad | Rawalpindi | Best Rates</Link>
<Link className="dropdown-item" to="/rent-a-audi-a5-search-a-car">Rent A Audi A5 | Search A Car | Car Rental Services</Link>
<Link className="dropdown-item" to="/rent-a-cars-services-in-gulistan-e-jauhar-block-15">Rent A Cars - Services in Gulistan-e-Jauhar Block 15</Link>
<Link className="dropdown-item" to="/car-rental-service-in-gulistan-e-jauhar-block-15">Car Rental Service in Gulistan-e-Jauhar Block 15</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-gulistan-e-johar-karachi">Rent a Car Service in Gulistan-e-Johar Karachi</Link>
<Link className="dropdown-item" to="/here-is-how-you-can-rent-a-car-in-gulistan-e-johar-karachi">Here's How You Can Rent a Car in Gulistan-e-Johar Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-gulistan-e-johar">Rent A Car Service In Gulistan-e-johar</Link>
<Link className="dropdown-item" to="/auto-rent-a-car-gulistan-e-johar-karachi">Auto Rent A Car Gulistan-e-Johar Karachi</Link>
<Link className="dropdown-item" to="/car-rental-service-in-gulshan-e-maymar">Car Rental Service in Gulshan-e-Maymar</Link>
<Link className="dropdown-item" to="/carplus-rent-a-car-gulshan-e-maymar">Carplus RENT A CAR Gulshan-e-Maymar</Link>
<Link className="dropdown-item" to="/karachi-car-rental-in-gulshan-e-maymar">Karachi - Car Rental in Gulshan-e-Maymar</Link>
<Link className="dropdown-item" to="/pick-and-drop-services-at-your-place-gulshan-e-maymar">Pick And Drop Services At Your Place. Gulshan-e-Maymar</Link>
<Link className="dropdown-item" to="/rent-a-cars-services-in-gulshan-e-iqbal-block-4">Rent A Cars - Services in Gulshan-E-Iqbal Block 4</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-gulshan-e-iqbal-karachi">Rent a Car Service in Gulshan-e-Iqbal Karachi</Link>
<Link className="dropdown-item" to="/rent-car-car-rental-in-gulshan-e-iqbal-block-4">Rent Car - Car Rental in Gulshan-E-Iqbal Block 4</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-gulshan-e-iqbal">Rent A Car Service In Gulshan-e-Iqbal</Link>
<Link className="dropdown-item" to="/car-hire-in-gulshan-e-iqbal-karachi">Car Hire in Gulshan-e-Iqbal, Karachi</Link>
<Link className="dropdown-item" to="/raza-khan-tours-rent-a-car-gulshan-e-iqbal-block-3">Raza Khan Tours - Rent a Car - Gulshan e Iqbal - Block 3</Link>
<Link className="dropdown -item" to="/cheap-car-hire-in-gulshan-e-iqbal">Cheap car hire in Gulshan-e-Iqbal</Link>
<Link className="dropdown-item" to="/royal-rent-a-car-in-karachi-3000pkr-per-day">Royal rent a car in karachi 3000PRK Per Day</Link>


<Link className="dropdown-item" to="/affordable-car-rental-service-in-gulshan-e-iqbal-karachi">Affordable Car Rental Service in Gulshan-e-Iqbal Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-gulshan-e-iqbal-karachi-contact-details">Rent A Car Gulshan-e-Iqbal, Karachi Contact Details</Link>
<Link className="dropdown-item" to="/hire-car-rentals-in-karachi">Hire Car Rentals in Karachi</Link>
<Link className="dropdown-item" to="/cheap-car-rentals-gulshan-e-iqbal">Cheap Car Rentals Gulshan-e-Iqbal</Link>
<Link className="dropdown-item" to="/dha-clifton-car-rental-in-karachi">DHA Clifton - Car Rental in Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-defence-karachi">Rent a Car Service in Defence Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-services-in-dha-phase-2">Rent A Car - Services in DHA Phase 2</Link>
<Link className="dropdown-item" to="/captain-car-rental-car-rental-karachi">Captain Car Rental | Car Rental Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-dha-karachi">Rent A Car DHA | Karachi</Link>
<Link className="dropdown-item" to="/dha-rent-a-car-service-karachi">DHA Rent a Car service | Karachi</Link>
<Link className="dropdown-item" to="/affordable-rent-a-car-dha-karachi">Affordable Rent A Car Dha Karachi</Link>
<Link className="dropdown-item" to="/car-rental-do-talwar-dha-karachi">Car Rental Do Talwar DHA Karachi</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-defence-zone">Rent A Car Service In Defence Zone</Link>
<Link className="dropdown-item" to="/car-rental-service-in-karachi-dha">Car Rental Service in Karachi DHA</Link>
<Link className="dropdown-item" to="/luxury-car-rentals-dha-karachi-head-office">Luxury Car Rentals DHA Karachi Head Office</Link>
<Link className="dropdown-item" to="/rent-a-car-dha-rent-a-car-clifton-hire-luxury-car-rental">Rent a Car DHA | Rent a Car Clifton | Hire Luxury Car Rental</Link>
<Link className="dropdown-item" to="/karachi-dha-rental-car-services">Karachi DHA Rental Car Services</Link>
<Link className="dropdown-item" to="/car-rental-service-in-clifton">Car Rental Service in Clifton</Link>
<Link className="dropdown-item" to="/rent-a-car-service-in-clifton-karachi">Rent a Car Service in Clifton Karachi</Link>
<Link className="dropdown-item" to="/karachi-car-rental-in-clifton">Karachi - Car Rental in Clifton</Link>





                                      {/* <Link to="/feature" class="dropdown-item">Our Feature</Link>
                                      <Link to="/categories/Cars/all-products" class="dropdown-item">Our Vehicles</Link>
                                      <Link to="/Corporate-Enquiries" class="dropdown-item">Corporate Enquiries</Link>
                                      <Link to="/checkout" class="dropdown-item">Booking Cart</Link> */}
                                      {/* <Link to="/team" class="dropdown-item">Our Team</Link> */}
                                      {/* <Link to="/testimonial" class="dropdown-item">Testimonial</Link> */}
                                      {/* <Link to="/404" class="dropdown-item">404 Page</Link> */}
                                  </div>
                              </div>
                              <Link to="/our-clients" class="nav-item nav-link">Clients </Link>
                              {/* <Link to="/service" class="nav-item nav-link">Feature</Link> */}
                              {/* <Link to="blog.html" class="nav-item nav-link">Blog</Link> */}
                              
                              <div class="nav-item dropdown">
                                  <a to="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Feature</a>
                                  <div class="dropdown-menu m-0">
                                      <Link to="/feature" class="dropdown-item">Our Feature</Link>
                                      <Link to="/service" class="dropdown-item">Our Vehicles</Link>
                                      <Link to="/Corporate-Enquiries" class="dropdown-item">Our Services</Link>
                                      <Link to="/checkout" class="dropdown-item">Booking Cart</Link>
                                      <Link to="/testimonial" class="dropdown-item">Testimonial</Link>
                                  </div>
                              </div>
                              
                              {/* <Link to="/contact" class="nav-item nav-link">Contact</Link> */}
                          </div>
                          <a href="tel:+923306007333" class="btn btn-primary rounded-pill py-2 px-4">Contact Now</a>
                      </div>
                  </nav>
              </div>
          </div>
        </>
        )
}

export default Header;