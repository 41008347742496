import React, { useState , useEffect } from 'react';
import {Link ,useHistory} from "react-router-dom"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import SendIcon from '@material-ui/icons/Send';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import { Flare } from '@material-ui/icons';
// import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import logo1 from '../assets/images/logo1.jpeg';
import payment from '../asset1/images/payments.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
  const Footer =(props)=> {
   

  //  history = useHistory();
  
        return (
            <>
         <div class="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-md-6 col-lg-6 col-xl-3">
                          <div class="footer-item d-flex flex-column">
                              <div class="footer-item">
                                  <h4 class="text-white mb-4">About Us</h4>
                                  <p class="mb-3"  style={{color:"white"}}>Welcome to One Click Rent a Car karachi, your premier destination for reliable and affordable car rental services in Karachi. Whether you're traveling for business, One Click Rent a Car karachi is here to make your journey smooth and enjoyable. Book with us today and discover the convenience and quality that set us apart.</p>
                              </div>
                              <div class="position-relative">
                                  <input class="form-control rounded-pill w-100 py-3 ps-4 pe-5" type="text" placeholder="Enter your email"/>
                                  {/* <button type="button" class="btn btn-secondary rounded-pill position-absolute top-0 end-0 py-2 mt-2 me-2">Subscribe</button> */}
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3">
                          <div class="footer-item d-flex flex-column">
                              <h4 class="text-white mb-4">Quick Links</h4>
                              <Link class="text-white" to="/"><i class="fas fa-angle-right me-2"></i> Home</Link>
                              <Link class="text-white" to="/about"><i class="fas fa-angle-right me-2"></i> About</Link>
                              <Link class="text-white" to="/categories/Cars/all-products"><i class="fas fa-angle-right me-2"></i> Cars Fleets</Link>
                              <Link class="text-white" to="/checkout"><i class="fas fa-angle-right me-2"></i> Booking Cart</Link>
                              {/* <Link to="/#"><i class="fas fa-angle-right me-2"></i> Team</Link> */}
                              <Link class="text-white" to="/contact"><i class="fas fa-angle-right me-2"></i> Contact us</Link>
                              <Link class="text-white" to="/term-policy"><i class="fas fa-angle-right me-2"></i> Terms & Conditions</Link>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3">
                          <div class="footer-item d-flex flex-column">
                              <h4 class="text-white mb-4">Business Hours</h4>
                              <div class="mb-3">
                                  <h6 style={{color:"white"}} class="text-muted mb-0">Mon - Friday:</h6>
                                  <p class="text-white mb-0">07.00 am to 12.00 pm</p>
                              </div>
                              <div class="mb-3">
                                  <h6 style={{color:"white"}} class="text-muted mb-0">Sat - Sun:</h6>
                                  <p class="text-white mb-0">08.00 am to 12.00 pm</p>
                              </div>
                              
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3" style={{color :"white"}}>
                          <div class="footer-item d-flex flex-column" style={{color :"white"}}>
                              <h4 class="text-white mb-4">Contact Info</h4>
                              <a href="https://maps.app.goo.gl/Vd8Q1jafRB2tstWp8" style={{color:"white"}}><i class="fa fa-map-marker-alt me-2"></i> C, 69 Khayaban-e-Shaheen, D.H.A Phase 6 Phase 6 Defence Housing Authority, Karachi, Karachi City, Sindh 75500</a>
                              <a href="mailto:oneclickrentacarkarachi@gmail.com" style={{color:"white"}}><i class="fas fa-envelope me-2"></i> oneclickrentacarkarachi@gmail.com</a>
                              <a href="tel:+923306007333" style={{color:"white"}}><i class="fas fa-phone me-2"></i> +92 330 600 7333</a>
                              {/* <a href="tel:+923306007333" class="mb-3"><i class="fas fa-print me-2"></i> +92 333 126 3110</a> */}
                              <div class="d-flex"  style={{color :"white"}}>
                                  <a class="btn-secondary btn-md-square rounded-circle me-3" href="https://www.facebook.com/webocreators"><i class="fab fa-facebook-f text-white"></i></a>
                                  <a class="btn-secondary btn-md-square rounded-circle me-3" href="http://tiktok.com/@webocreators"><i class="fab fa-tiktok text-white"></i></a>
                                  <a class="btn-secondary btn-md-square rounded-circle me-3" href="http://instagram.com/webocreators"><i class="fab fa-instagram text-white"></i></a>
                                  <a class="btn-secondary btn-md-square rounded-circle me-0" href="https://www.linkedin.com/in/webocreators"><i class="fab fa-linkedin-in text-white"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          
          <div class="container-fluid copyright py-4">
              <div class="container">
                  <div class="row g-4 align-items-center">
                      <div class="col-md-6 text-center text-md-start mb-md-0">
                          <span class="text-body"><a href="#" class="border-bottom text-white"><i class="fas fa-copyright text-light me-2"></i>One Click Rent a Car karachi</a>, All right reserved.</span>
                      Design by <a href="https://webocreators.com">Webo Creators</a>
                      </div>
                      <div class="col-md-6 text-center text-md-end text-body">
                      </div>
                  </div>
              </div>
          </div>
  
         
            </>
        )
    
}

export default Footer;







